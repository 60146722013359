exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-directors-tsx": () => import("./../../../src/pages/directors.tsx" /* webpackChunkName: "component---src-pages-directors-tsx" */),
  "component---src-pages-image-resizer-tsx": () => import("./../../../src/pages/image_resizer.tsx" /* webpackChunkName: "component---src-pages-image-resizer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-movies-tsx": () => import("./../../../src/pages/movies.tsx" /* webpackChunkName: "component---src-pages-movies-tsx" */),
  "component---src-pages-over-under-tsx": () => import("./../../../src/pages/over_under.tsx" /* webpackChunkName: "component---src-pages-over-under-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/AuthorPage.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-book-page-tsx": () => import("./../../../src/templates/BookPage.tsx" /* webpackChunkName: "component---src-templates-book-page-tsx" */),
  "component---src-templates-director-page-tsx": () => import("./../../../src/templates/DirectorPage.tsx" /* webpackChunkName: "component---src-templates-director-page-tsx" */),
  "component---src-templates-movie-page-tsx": () => import("./../../../src/templates/MoviePage.tsx" /* webpackChunkName: "component---src-templates-movie-page-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/ProjectPage.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */)
}

